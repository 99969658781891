import React, { useEffect } from "react";
import { useHomePageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import { Hero, Slices, LatestNews } from "@organisms";
import { navigateTo } from "gatsby";

const HomePage = pageProps => {
  const { hero, slices, prefooter, meta } = useHomePageData();
  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash === "careers") {
      navigateTo("/careers");
    }
  }, []);
  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {hero?.image?.url && <Hero {...hero} />}
      <Slices slices={slices} />
      <LatestNews {...prefooter} />
    </DefaultLayout>
  );
};

export default HomePage;
